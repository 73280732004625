// React
// Helpers
import React from 'react';
import dynamic from 'next/dynamic';
import { useBlog, useFaq } from 'src/services';
import { withRedux } from '@utils';
import { wrapper } from '@store';

// Third Party Modules
// Store

const HomeHero = dynamic(() => import('../src/features/home/home-hero'));
const BlogList = dynamic(() => import('../src/features/home/blog-list'));
const SSQList = dynamic(() => import('../src/features/home/ssq-list'));

type Props = {
  faqData: any;
  blogData: any;
};
// Home
const Home = ({ faqData, blogData }: Props) => {
  return (
    <>
      <HomeHero />
      <BlogList blogData={blogData} />
      <SSQList faqData={faqData} />
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps((store) =>
  withRedux(
    async () => {
      const { getFaqList } = useFaq();
      const { getBlogList } = useBlog();
      try {
        const [faqData, blogData] = await Promise.all([
          getFaqList()
            .then((res) => res.data)
            .catch(() => []),
          getBlogList()
            .then((res) => res.data)
            .catch(() => []),
        ]);

        return { props: { faqData, blogData } };
      } catch (error) {
        return { notFound: true };
      }
    },

    { store },
  ),
);

// ServerSide Props
// export const getServerSideProps = wrapper.getServerSideProps((store) =>
//   withRedux(
//     async () => {
//       // Store Erişmek için SSR tarafında
//       const app = selectApp(store.getState());
//       // console.log({ app });
//       // Api
//       try {
//         // Get Data
//         const [comics1, comics2, comics3] = await Promise.all([
//           // await getUsername(),
//           (
//             await axios.get('https://www.breakingbadapi.com/api/')
//           ).data,
//           (await axios.get('https://www.breakingbadapi.com/api/')).data,
//           (await axios.get('https://www.breakingbadapi.com/api/')).data,
//         ]);
//         // Return
//         return { props: { comics1, comics2, comics3 } };
//       } catch (e) {
//         return { notFound: true };
//       }
//     },
//     { store },
//   ),
// );
// Export
export default Home;
